import "./Navbar.style.css";
import { Navbar, Container, Nav } from "react-bootstrap";

const TopNavbar = (props) => {
  return (
    <Navbar className="navbar-distributed" expand="lg">
      <Container>
        <Navbar.Brand
          href="https://www.mittelstand-digital-wertnetzwerke.de/"
          target="_blank"
        >
          <img
            src="logo-mdz-wnw.png"
            alt="Logo Mittelstand Digital-Zentrum WertNetzWerke"
            id="Mittelstand-Logo"
          />
        </Navbar.Brand>
        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        <Navbar.Collapse id="basic-navbar-nav">
          <Nav className="me-auto">
            <Nav.Link
              id="navbar-link"
              onClick={() => {
                props.onChangePage("MainPage");
              }}
            >
              Startseite
            </Nav.Link>
            <Nav.Link
              id="navbar-link"
              onClick={() => {
                props.onChangePage("MaterialPage");
              }}
            >
              Materialien
            </Nav.Link>
            <Nav.Link
              id="navbar-link"
              onClick={() => {
                props.onChangePage("AboutUsPage");
              }}
            >
              Über uns
            </Nav.Link>
            <Nav.Link
              id="navbar-link"
              onClick={() => {
                props.onChangePage("ContactPage");
              }}
              style={{ paddingRight: "40px" }}
            >
              Kontakt
            </Nav.Link>
          </Nav>
          <Nav className="guide-label">Kreislaufwirtschafts-Guide</Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
};

export default TopNavbar;
