import { Navbar, Container, Nav, Form } from "react-bootstrap";
import "./ViewNavigator.style.css";

const ViewNavigator = (props) => {
  let toggleVisibility = {
    Intro: true,
    EcoDesign: true,
    Strategies: true,
    Partners: false,
  };

  return (
    <Navbar className="navbar-distributed view-navbar">
      <Container className="view-bar-container">
        <Nav variant="tabs" defaultActiveKey="Intro">
          <Nav.Item>
            <Nav.Link
              eventKey="Intro"
              id="view-nav-link"
              onClick={() => {
                props.onChangeView("Intro");
              }}
            >
              Intro
            </Nav.Link>
          </Nav.Item>
          <Nav.Item>
            <Nav.Link
              eventKey="EcoDesign"
              id="view-nav-link"
              onClick={() => {
                props.onChangeView("EcoDesign");
              }}
            >
              Eco-design
            </Nav.Link>
          </Nav.Item>
          <Nav.Item>
            <Nav.Link
              eventKey="Strategies"
              id="view-nav-link"
              onClick={() => {
                props.onChangeView("Strategies");
              }}
            >
              Strategien
            </Nav.Link>
          </Nav.Item>
          <Nav.Item>
            <Nav.Link
              eventKey="Partners"
              id="view-nav-link"
              onClick={() => {
                props.onChangeView("Partners");
              }}
            >
              Partnerschaften
            </Nav.Link>
          </Nav.Item>
        </Nav>
        {toggleVisibility[props.currentView] ? (
          <div className="economy-switch-div">
            <label title="" className="form-check-label left-label">
              Linearwirtschaft
            </label>
            <Form.Check
              type="switch"
              id="economy-switch"
              label="Kreislaufwirtschaft"
              onChange={props.onChangeEconomySwitch}
              defaultChecked
            />
          </div>
        ) : (
          <></>
        )}
      </Container>
    </Navbar>
  );
};

export default ViewNavigator;
