import React, { useEffect, useState } from "react";
 
const PrivacyPage = () => {
  const [content, setContent] = useState("");
  const [error, setError] = useState(false);
 
  const generator_url_dsi =
    "https://dsi-generator.fraunhofer.de/dsi/view/de/9695533b-1a3b-4a01-833a-d45be09bfaf9/";
 
  const generator_errormsg = (
    <div>
      <strong>
        Fehler beim Abrufen des Inhalts.
        <br />
        &nbsp;
      </strong>
      <div>
        <strong>
          Die Datenschutzinformationen können Sie später unter{" "}
          <em>
            <a href={generator_url_dsi}>{generator_url_dsi}</a>
          </em>{" "}
          herunterladen.
        </strong>
      </div>
    </div>
  );
 
  useEffect(() => {
    fetch(generator_url_dsi)
      .then((response) => {
        if (!response.ok) {
          throw new Error("Network response was not ok");
        }
        return response.text();
      })
      .then((result) => {
        setContent(result);
      })
      .catch(() => {
        setError(true);
      });
  }, []);
 
  return (
    <div
      id="generator_contentwrapper_dsi"
      style={{
        margin: "0 auto",
        padding: "0 2rem",
      }}
    >
      {error ? (
        generator_errormsg
      ) : content ? (
        <div dangerouslySetInnerHTML={{ __html: content }} />
      ) : (
        <div>
          <strong>
            Sie haben JavaScript nicht aktiviert.
            <br />
            &nbsp;
          </strong>
          <div>
            <strong>
              Die Datenschutzinformationen können Sie{" "}
              <em>
                <a href={generator_url_dsi}>hier</a>
              </em>{" "}
              herunterladen.
            </strong>
          </div>
        </div>
      )}
    </div>
  );
};
 
export default PrivacyPage;