import React, { useState } from "react";

import "bootstrap/dist/css/bootstrap.min.css";
import "./App.style.css";

import CompanyInformationPage from "../../Pages/CompanyInformationPage/CompanyInformationPage.js";
import PrivacyPage from "../../Pages/PrivacyPage/PrivacyPage.js";
import AboutUsPage from "../../Pages/AboutUsPage/AboutUsPage";
import Navbar from "../Navbar/Navbar";
import Footer from "../Footer/Footer";
import Data from "../../Assets/Content/ContentData.js";
import ContactPage from "../../Pages/ContactPage/ContactPage";
import MaterialPage from "../../Pages/MaterialPage/MaterialPage";
import MainPage from "../../Pages/MainPage/MainPage";

const App = () => {
  const [page, setPage] = useState("MainPage");
  const [content, setContent] = useState(Data.CycleMain);
  const [economyType, setEconomyType] = useState("cycle");

  let onChangePage = (e) => {
    setPage(e);
  };

  let getPage = () => {
    if (page === "CompanyInformationPage") {
      return <CompanyInformationPage />;
    } else if (page === "PrivacyPage") {
      return <PrivacyPage />;
    } else if (page === "MainPage") {
      return (
        <MainPage
          AppStateService={{ content, setContent, economyType, setEconomyType }}
        />
      );
    } else if (page === "MaterialPage") {
      return <MaterialPage />;
    } else if (page === "AboutUsPage") {
      return <AboutUsPage />;
    } else if (page === "ContactPage") {
      return <ContactPage />;
    }
  };

  return (
    <>
      <Navbar onChangePage={onChangePage} />
      {getPage()}
      <Footer onChangePage={onChangePage} />
    </>
  );
};

export default App;
