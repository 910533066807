import { useEffect } from "react";

const CompanyInformationPage = () => {
  useEffect(() => {
    document.title = "Impressum";
  }, []);

  return (
    <div className="Page">
      <h1>Impressum</h1>

      <div>
        Das <br />
        <br />
        Fraunhofer-Institut für Angewandte Informationstechnik FIT <br />
        Schloss Birlinghoven <br />
        53757 Sankt Augustin <br />
        <br />
        ist eine rechtlich nicht selbstständige Einrichtung der <br />
        <br />
        Fraunhofer-Gesellschaft <br />
        zur Förderung der angewandten Forschung e.V. <br />
        Hansastraße 27 c <br />
        80686 München <br />
        Internet:{" "}
        <a href="http://www.fraunhofer.de/" target="_blank" rel="noreferrer">
          www.fraunhofer.de
        </a>{" "}
        <br />
        E-Mail:{" "}
        <a href="mailto:info(at)zv.fraunhofer.de ">
          info(at)zv.fraunhofer.de
        </a>{" "}
        <br />
        <br />
        <h4>Verantwortliche Redakteurin</h4>
        <p>
          Lena Köppen <br />
          Email:{" "}
          <a href="mailto:lena.koeppen@fit.fraunhofer.de">
            lena.koeppen@fit.fraunhofer.de
          </a>{" "}
          <br />
          Telefon: <a href="tel:+49 2241 14 3712">+49 2241 14 3712</a> <br />
        </p>
        <p>
          Umsatzsteuer-Identifikationsnummer gemäß § 27 a <br />
          Umsatzsteuergesetz: DE 129515865 <br />
          <br />
          Registergericht
          <br />
          Amtsgericht München
          <br />
          Eingetragener Verein
          <br />
          Register-Nr. VR 4461
          <br />
          <br />
        </p>
        <h4>Vorstand</h4>
        <p>
          <b>Prof. Dr.-Ing. Holger Hanselka</b> | Präsident
          <br />
          <b>Prof. Dr. Constantin Häfner</b> | Mitglied des Vorstands
          <br />
          <b>Prof. Dr. Axel Müller-Groeling</b> | Mitglied des Vorstands
          <br />
          <b>Elisabeth Ewen</b> | Mitglied des Vorstands
          <br />
          <b>Dr. Sandra Krey</b> | Mitglied des Vorstands
          <br />
        </p>
        <h4>Nutzungsrechte</h4>
        © Fraunhofer-Gesellschaft e.V., München
        <br />
        <br />
        Alle Rechte vorbehalten.
        <br />
        Die Urheberrechte dieser Webseite liegen vollständig bei der
        Fraunhofer-Gesellschaft.
        <br />
        Ein Download oder Ausdruck dieser Veröffentlichungen ist ausschließlich
        für den persönlichen Gebrauch gestattet. Alle darüber hinaus gehenden
        Verwendungen, insbesondere die kommerzielle Nutzung und Verbreitung,
        sind grundsätzlich nicht gestattet und bedürfen der schriftlichen
        Genehmigung.
        <br />
        Ein Download oder Ausdruck ist darüber hinaus lediglich zum Zweck der
        Berichterstattung über die Fraunhofer-Gesellschaft und ihrer Institute
        nach Maßgabe untenstehender Nutzungsbedingungen gestattet: Grafische
        Veränderungen an Bildmotiven - außer zum Freistellen des Hauptmotivs -
        sind nicht gestattet. Es ist stets die Quellenangabe und Übersendung von
        zwei kostenlosen Belegexemplaren an die oben genannte Adresse
        erforderlich. Die Verwendung ist honorarfrei. <br />
        <br />
        <h4>Haftungshinweis</h4>
        Wir übernehmen keine Haftung für die Inhalte externer Links. Für den
        Inhalt der verlinkten Seiten sind ausschließlich deren Betreiber
        verantwortlich. Wir sind bemüht, das Webangebot stets aktuell und
        inhaltlich richtig sowie vollständig anzubieten. Dennoch ist das
        Auftreten von Fehlern nicht völlig auszuschließen. Das
        Fraunhofer-Institut bzw. die Fraunhofer-Gesellschaft übernimmt keine
        Haftung für die Aktualität, die inhaltliche Richtigkeit sowie für die
        Vollständigkeit der in ihrem Webangebot eingestellten Informationen.
        Dies bezieht sich auf eventuelle Schäden materieller oder ideeller Art
        Dritter, die durch die Nutzung dieses Webangebotes verursacht wurden.
        Geschützte Marken und Namen, Bilder und Texte werden auf unseren Seiten
        in der Regel nicht als solche kenntlich gemacht. Das Fehlen einer
        solchen Kennzeichnung bedeutet jedoch nicht, dass es sich um einen
        freien Namen, ein freies Bild oder einen freien Text im Sinne des
        Markenzeichenrechts handelt.
      </div>
    </div>
  );
};

export default CompanyInformationPage;
